import React, {useEffect, useState} from "react";
import {FieldValues, FormProvider, useForm} from "react-hook-form";
import axios from "axios";
import {Product} from "../../../types/Product";
import {getBackendURL} from "../../../utils/EnvironmentsManager";
import toast from "react-hot-toast";
import {toastError} from "../../../utils/ErrorHandlerUtils";
import {PreOrderProductSettings} from "./PreOrderProductSettings";
import DestructiveButton from "../../../components/buttons/DestructiveButton";
import MainButton from "../../../components/buttons/MainButton";
import Spinner from "../../../components/loading/Spinner";
import {usePowerUpsProvider} from "../../../providers/PowerUpsProvider";
import {ProductConfig} from "../../../types/ProductConfig";
import {PreOrderRule} from "../types/PreOrderRule";

const PreOrderForm = () => {
    const {closeCustomizeMenu, disablePowerUp} = usePowerUpsProvider()
    const methods = useForm();

    const [isSendingData, setIsSendingData] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [products, setProducts] = useState<Product[] | undefined>();
    const [productConfigs, setProductConfigs] = useState<ProductConfig[] | undefined>();

    const [preOrderRules, setPreOrderRules] = useState<PreOrderRule[] | undefined>();

    useEffect(() => {
        getPreOrderRules()
    }, []);

    useEffect(() => {
        getProducts();
    }, [preOrderRules]);

    const getPreOrderRules = () => {
        axios.get(getBackendURL() + "/api/v1/power-ups/preorder/list").then(result => {
            setPreOrderRules(result.data);
        });
    };

    const getProducts = () => {
        if (!preOrderRules) {
            return
        }

        axios.get(getBackendURL() + "/api/v1/product/list").then(result => {
            const products:Product[] = result.data;

            setProducts(products);

            setProductConfigs(products.map((product: Product) => ({
                product: product,
                checked: product.variants.some((v) => {
                    return preOrderRules.some((r) => r.variantId == v.id )
                })
            })));
        });
    };

    const checkProduct = (individualProduct: ProductConfig) => {
        if (isSendingData) {
            return;
        }

        const isChecked = !individualProduct.checked;

        setProductConfigs(prevProducts =>
            prevProducts!.map(product =>
                product === individualProduct ? {...product, checked: isChecked} : product
            )
        );

        if (!isChecked) {
            individualProduct.product.variants.map(variant => {
                methods.resetField(variant.id + "_preorder");
                methods.resetField(variant.id + "_endDate");

                methods.unregister(variant.id + "_preorder");
                methods.unregister(variant.id + "_endDate");
            })
        }
    };

    const onSubmit = (values: FieldValues) => {
        if (isSendingData) {
            return;
        }
        setIsSendingData(true);
        setIsSaving(true);

        console.log(values);

        const payload = {
            productVariants: productConfigs?.map(productConfig => {
                let list: any = []
                productConfig.product.variants.map(variant => {
                    list.push({
                        variantId: variant.id,
                        expectedDelivery: (productConfig.checked ? values[variant.id + "_endDate"] : undefined),
                    })
                })
                return list
            }).flat()
        }

        axios.post(getBackendURL() + "/api/v1/power-ups/preorder/save-preorder-settings", payload)
            .then(result => {
                toast.success("Saved!");
                closeCustomizeMenu();
            })
            .catch(error => {
                toastError(error);
            })
            .finally(() => {
                setIsSendingData(false);
                setIsSaving(false);
            });
    };

    const disablePowerUpClicked = () => {
        if (isSendingData) {
            return;
        }
        setIsSendingData(true);
        disablePowerUp()
    };

    const userAttemptsCancel = () => {
        if (isSendingData) {
            return;
        }

        closeCustomizeMenu();
    };

    return (
        <div>
            {products ? (
                <FormProvider {...methods}>
                    <form className={"mt-8"} onSubmit={methods.handleSubmit(onSubmit)}>
                        <div className={"max-h-96 overflow-y-scroll"}>
                            {preOrderRules && productConfigs?.map(productConfig => (
                                <PreOrderProductSettings
                                    key={productConfig.product.id}
                                    productConfig={productConfig}
                                    checkProduct={checkProduct}
                                    allRules={preOrderRules}
                                />
                            ))}
                        </div>
                        <div className="mt-5 flex justify-center gap-5">
                            <DestructiveButton onClickEvent={disablePowerUpClicked}
                                               disabled={isSendingData}>Deaktiver</DestructiveButton>
                            <MainButton showProcessingAnimation={isSaving} disabled={isSendingData}>Lagre</MainButton>
                        </div>
                    </form>
                </FormProvider>
            ) : (
                <Spinner className="flex justify-center"/>
            )}
        </div>
    );
};

export default PreOrderForm;
