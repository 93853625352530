import {AnimatePresence, motion} from "framer-motion";
import MainButton from "../../components/buttons/MainButton";
import React from "react";
import {Card, Divider} from "@mui/material";
import Heading1 from "../../components/text/Heading1";
import {InformationCircleIcon} from "@heroicons/react/16/solid";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import HeadingDescriptor from "../../components/text/HeadingDescriptor";
import Subtitle from "../../components/text/Subtitle";
import DividerWithText from "../../components/authentication/DividerWithText";
import Heading2 from "../../components/text/Heading2";
import VideoToolTip from "../../VideoToolTip";

interface ConfigureAppStepProps {
    onBackPressed: () => void;
    onNextPressed: () => void;
    stepVisible: boolean;
}

const ConfigureAppStep = ({onBackPressed, onNextPressed, stepVisible}: ConfigureAppStepProps) => {
    return (
        <>
            <AnimatePresence>
                {stepVisible && (
                    <motion.div
                        initial={{opacity: 0, scaleY: 0}}
                        animate={stepVisible ? {opacity: 1, scaleY: 1, transition: {delay: 0.6, duration: 0.2}} : {}}
                        exit={{opacity: 0, scaleY: 0}}
                        transition={{duration: 0.1}}
                        className={"flex items-center justify-center h-full"}
                    >
                        <Card
                            className={"flex flex-col items-center md:items-start text-center md:text-left max-h-[650px] w-full max-w-4xl p-6 bg-white shadow-md"}>
                            <div className="w-full h-[70vh] overflow-y-auto p-10">
                                <Heading1 leftAligned={true}>Konfigurer app</Heading1>
                                <HeadingDescriptor withoutMargin={true} leftAligned={true}>La oss starte med å aktivere
                                    "custom app development" </HeadingDescriptor>
                                <VideoToolTip
                                    videoSrc={"https://fast.wistia.com/embed/medias/kgw6cjmt3m"}
                                    infoText="Følg stegene i denne videoen for å komme igang,"
                                />
                                <div className="border-b border-gray-200 mt-10"></div>
                                <div className="mt-12 w-full">
                                    <p className="text-lg text-left mb-4">
                                        <Heading2>Steg 1</Heading2>
                                        På adminsiden til Shopify klikk på <strong>“Settings”</strong> &gt;
                                        <a href="https://admin.shopify.com/settings/apps"
                                           className="text-blue-500 underline" target="_blank"
                                           rel="noopener noreferrer"> Apps and sales channels</a>
                                    </p>
                                    <img src={"/enthemed-tutorial/shopifytutorialsteg1.jpg"} alt="Steg 1"/>

                                    <p className="my-4 text-lg mt-12 text-left">
                                        <Heading2>Steg 2</Heading2>
                                        Trykk på knappen <strong>"develop apps"</strong></p>
                                    <img src={"/enthemed-tutorial/shopifytutorialsteg2.jpg"} alt="Steg 2"/>

                                    <p className="my-4 text-lg mt-12 text-left">
                                        <Heading2>Steg 3</Heading2>
                                        Da vil du få opp teksten <strong>“Start
                                        developing apps for your store.”</strong> Les vilkårene nøye, dermed kan du
                                        klikke deg videre ved å trykke på <strong>“Allow custom app
                                        development”</strong></p>
                                    <img src={"/enthemed-tutorial/shopifytutorialsteg3.jpg"} alt="Steg 3"/>

                                    <p className="my-4 text-lg mt-12 text-left">
                                        <Heading2>Steg 4</Heading2>
                                        Du vil dermed få opp en popup som sier at
                                        handlingen ikke kan bli reversert. Klikk på <strong>“Allow custom app
                                        development”</strong></p>
                                    <img src={"/enthemed-tutorial/shopifytutorialsteg4.jpg"} alt="Steg 4"/>
                                    <div className="border-b border-gray-200 mt-10"></div>

                                    <div className="flex justify-center gap-10 mt-10">
                                        <SecondaryButton onClickEvent={onBackPressed}>Gå tilbake</SecondaryButton>
                                        <MainButton onClickEvent={onNextPressed}>Gå videre</MainButton>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default ConfigureAppStep;
