import * as React from 'react';
import { ReactNode, useEffect, useState } from "react";
import WelcomeStep from "../pages/onboarding/WelcomeStep";
import FinalStep from "../pages/onboarding/FinalStep";
import { useCurrentUser } from "./UserProvider";
import InputStoreNameStep from "../pages/onboarding/InputStoreNameStep";
import { useSearchParams } from "react-router-dom";
import SubscriptionSucceedStep from "../pages/onboarding/SubscriptionSucceedStep";
import axios from "axios";
import { getBackendURL } from "../utils/EnvironmentsManager";
import { toastError } from "../utils/ErrorHandlerUtils";
import ConnectToShopifyStep from "../pages/onboarding/ConnectToShopifyStep";
import IntegrateWithShopifyStep from "../pages/onboarding/IntegrateWithShopifyStep";
import ConfigureAppStep from "../pages/onboarding/ConfigureAppStep";
import SetUpAppStep from "../pages/onboarding/SetUpAppStep"; // Import the new step

interface OnboardingWrapperProps {
    children?: ReactNode;
}

const OnboardingProvider = ({ children }: OnboardingWrapperProps) => {
    const { currentUser, refreshUser } = useCurrentUser();
    const [currentStep, setCurrentStep] = useState(0);
    const [searchParams] = useSearchParams();

    // Function to update LocalStorage and set the current step
    const updateStep = (step: number) => {
        setCurrentStep(step);
        localStorage.setItem("currentStep", step.toString());
    };

    const nextPageClicked = () => {
        const nextStep = currentStep + 1;
        updateStep(nextStep);
    };

    const backPageClicked = () => {
        const nextStep = currentStep - 1;
        updateStep(nextStep);
    };

    const finalStepPressed = () => {
        axios.post(getBackendURL() + "/api/v1/user/complete-onboarding").then(result => {
            refreshUser();
        }).catch(error => {
            toastError(error);
        });
    };

    useEffect(() => {
        const urlStep = parseInt(searchParams.get("step") || "0", 10);
        const storedStep = parseInt(localStorage.getItem("currentStep") || "0", 10);

        if (urlStep >= storedStep) {
            updateStep(urlStep);
        } else {
            setCurrentStep(storedStep);
        }
    }, [searchParams]);

    return (
        <>
            {currentUser && !currentUser.completedOnboarding ? (
                <>
                    <div className={"flex flex-col items-center justify-center h-screen mx-5 "}>
                        <WelcomeStep stepVisible={currentStep === 0} onNextPressed={nextPageClicked}/>
                        <SubscriptionSucceedStep stepVisible={currentStep === 1} onNextPressed={nextPageClicked}/>
                        <InputStoreNameStep stepVisible={currentStep === 2} onNextPressed={nextPageClicked}/>
                        <IntegrateWithShopifyStep stepVisible={currentStep === 3} onNextPressed={nextPageClicked}/>
                        <ConfigureAppStep stepVisible={currentStep === 4} onNextPressed={nextPageClicked} onBackPressed={backPageClicked}/>
                        <SetUpAppStep stepVisible={currentStep === 5} onNextPressed={nextPageClicked} onBackPressed={backPageClicked}/>
                        <ConnectToShopifyStep stepVisible={currentStep === 6} onNextPressed={nextPageClicked} onBackPressed={backPageClicked}/>
                        <FinalStep stepVisible={currentStep === 7} onNextPressed={finalStepPressed}/>
                    </div>
                </>
            ) : (
                <>
                    {children}
                </>
            )}
        </>
    );
};

export default OnboardingProvider;
