import React, {useState} from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import {Toaster} from "react-hot-toast";
import {LoginProvider} from "./providers/LoginProvider";
import SignInPage from "./pages/authentication/SignInPage";
import SignUpPage from "./pages/authentication/SignUpPage";
import ForgotPasswordPage from "./pages/authentication/ForgotPasswordPage";
import ChangePasswordPage from "./pages/authentication/ChangePasswordPage";
import axios from "axios";
import {UserProvider} from "./providers/UserProvider";
import OnboardingProvider from "./providers/OnboardingProvider";
import {RequiresLicense} from "./providers/LicenseProvider";
import PageWrapper from "./PageWrapper";
import ThemePage from "./pages/ThemePage";
import PowerUpsPage from "./pages/power-ups/PowerUpsPage";
import PrivacyPolicy from "./pages/legal-pages/PrivacyPolicy";
import TermsOfService from "./pages/legal-pages/TermsOfService";
import DeleteMeGuidePage from "./pages/legal-pages/DeleteMeGuidePage";
import GeneralSettingsPage from "./pages/settings/GeneralSettingsPage";
import ExtraStorePage from "./pages/extra-store/ExtraStorePage";

function App() {
    axios.defaults.withCredentials = true
    const [sidebarOpen, setSidebarOpen] = useState(false)


    return (
        <>
            <Toaster position={"top-center"}/>
            <BrowserRouter>
                <LoginProvider>
                    <Routes>
                        <Route path={"/login"} element={<SignInPage/>}/>
                        <Route path={"/register"} element={<SignUpPage/>}/>
                        <Route path={"/forgot-password"} element={<ForgotPasswordPage/>}/>
                        <Route path={"/change-password"} element={<ChangePasswordPage/>}/>
                        <Route path={"/delete-me"} element={<DeleteMeGuidePage/>}/>
                    </Routes>
                </LoginProvider>
                <Routes>
                    <Route path={"/privacy-policy"} element={<PrivacyPolicy/>}/>
                    <Route path={"/terms-of-service"} element={<TermsOfService/>}/>
                </Routes>
                <UserProvider>
                    <OnboardingProvider>
                        <RequiresLicense>
                            <PageWrapper sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
                                <Routes>
                                    <Route path={"/"} element={<HomePage/>}/>
                                    <Route path={"/themes"} element={<ThemePage/>}/>
                                    <Route path={"/power-ups"} element={<PowerUpsPage/>}/>
                                    <Route path={"/settings"} element={<GeneralSettingsPage/>}/>
                                    <Route path={"/add-store"} element={<ExtraStorePage/>}/>
                                </Routes>
                            </PageWrapper>
                        </RequiresLicense>
                    </OnboardingProvider>
                </UserProvider>
            </BrowserRouter>
        </>
    );
}

export default App;
