import React from "react";
import CheckBoxComponent from "../../../components/checkboxes/CheckBoxComponent";
import AutocompleteDropdown from "../../../components/input-fields/AutocompleteDropdown";
import InputPercentField from "../../../components/input-fields/InputPercentField";
import {MenuOption} from "../../../components/input-fields/DropdownMenu";
import {ProductSuggestionRule} from "../types/ProductSuggestionRule";
import {ProductConfig} from "../../../types/ProductConfig";

interface ProductSuggestionProductSettingsProps {
    individualProduct: ProductConfig;
    productOptions: MenuOption[];
    productSuggestionRules: ProductSuggestionRule[];
    checkProduct: (individualProduct: ProductConfig) => void;
}

export const ProductSuggestionProductSettings = ({
                                      individualProduct,
                                      productOptions,
                                      productSuggestionRules,
                                      checkProduct,
                                  }: ProductSuggestionProductSettingsProps) => {
    const suggestionRule = productSuggestionRules.find(
        (suggestion) => suggestion.sourceProductId === individualProduct.product.id
    );

    return (
        <div className="mt-5">
            <div className="flex">
                <div className="flex basis-10 items-center">
                    <CheckBoxComponent
                        onClickEvent={() => checkProduct(individualProduct)}
                        name={`${individualProduct.product.id}_checkbox`}
                        isCheckedByDefault={individualProduct.checked}
                    />
                </div>
                <div className="basis-full">
                    <div>Når</div>
                    <div className="text-lg font-bold">{individualProduct.product.title}</div>
                    <div>er i handlekurven</div>
                </div>
            </div>
            {individualProduct.checked && (
                <div className="mt-5 ml-10 grid gap-4 sm:grid-cols-2">
                    <div className="flex flex-row basis-full items-center">
                        <span className="w-40">Foreslå alltid</span>
                        <AutocompleteDropdown
                            name={`${individualProduct.product.id}_suggestion`}
                            label="Velg produkt"
                            options={productOptions}
                            style={{width: "100%"}}
                            defaultValue={productOptions.find((option) =>
                                suggestionRule
                                    ? option.id === `${suggestionRule.suggestedProductId}_${suggestionRule.suggestedProductVariantId}`
                                    : false
                            )}
                            validation={{
                                required: {value: true, message: "Påkrevd felt"}
                            }}
                        />
                        &nbsp;
                    </div>

                    <div className="flex flex-row basis-full items-center">
                        <span className="mr-2">med</span>
                        <InputPercentField
                            name={`${individualProduct.product.id}_discount`}
                            label=""
                            style={{width: "110px"}}
                            value={suggestionRule?.discount}
                            placeholder={"10"}
                            validation={{
                                min: {value: 0, message: "For lavt"},
                                max: {value: 100, message: "For høyt"}
                            }}/>
                        <span className="ml-2">rabatt på hvert produkt</span>
                    </div>
                </div>
            )}
            <hr className="mt-5"/>
        </div>
    );
};