import ListItem from "@mui/material/ListItem";
import React, {Dispatch, ReactElement, SetStateAction} from "react";
import {NavLink} from "react-router-dom";

interface SidebarListItemProps {
    sidebarVisible: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    text: string
    icon: ReactElement<any, any>
    link: string
    selectedSidebarItem: string
    setSelectedSidebarItem: Dispatch<SetStateAction<string>>
}

const SidebarListItem = ({text, icon, sidebarVisible, setOpen, link, selectedSidebarItem, setSelectedSidebarItem}: SidebarListItemProps) => {
    const itemChosen = () => {
        setSelectedSidebarItem(text)
        setOpen(false)
    }

    console.log(selectedSidebarItem + " : " + text)

    return (
        <ListItem key={text} disablePadding sx={{display: 'block'}}>
            <NavLink to={link} onClick={itemChosen}>
                <div className={
                    `flex items-center mb-11 hover:text-main-color ${selectedSidebarItem === text ? "text-main-color" : "text-gray-400"}`}>
      <span className={`flex-shrink-0 ${selectedSidebarItem === text ? "text-main-color" : "text-gray-400"} ${sidebarVisible ? "ml-5" : "ml-5"}`}>
        {icon}
      </span>{sidebarVisible ? (
                    <span className={`ml-2 whitespace-nowrap ${selectedSidebarItem === text ? "text-main-color" : "text-gray-400"}`}>
          {text}
        </span> ) : ( <span className={`absolute top-8 left-0 right-0 text-xs whitespace-nowrap text-center ${selectedSidebarItem === text ? "text-main-color" : "text-gray-400"}`}>
          {text}
        </span>
                )}
                </div>
            </NavLink>
        </ListItem>
    )
}

export default SidebarListItem