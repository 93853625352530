import SettingsFragment from "./SettingsFragment";
import DestructiveButton from "../../../components/buttons/DestructiveButton";
import toast from "react-hot-toast";
import InputTextField from "../../../components/input-fields/InputTextField";
import {FieldValues, FormProvider, useForm} from "react-hook-form";
import MainButton from "../../../components/buttons/MainButton";
import axios from "axios";
import {getBackendURL} from "../../../utils/EnvironmentsManager";
import PasswordInput from "../../../components/input-fields/PasswordInput";
import {toastError} from "../../../utils/ErrorHandlerUtils";
import {useCurrentUser} from "../../../providers/UserProvider";
import Heading2 from "../../../components/text/Heading2";
import HeadingDescriptor from "../../../components/text/HeadingDescriptor";
import React, {useState} from "react";

const AccountFragmentNode = () => {
    const methods = useForm();
    const {currentUser} = useCurrentUser();

    const [creatingStripePortalLink, setCreatingStripePortalLink] = useState<boolean>(false)
    const [showDeleteInformation, setShowDeleteInformation] = useState<boolean>(false)

    const onSubmit = (values: FieldValues) => {
        const transformedData = Object.fromEntries(
            Object.entries(values).map(([key, value]) => [key, value === '' ? null : value])
        );

        axios.put(getBackendURL() + "/api/v1/user", transformedData).then(() => {
            toast.success("Profile updated");
        }).catch((error) => {
            if (error.response.status === 302 || error.response.status === 404) {
                toast.error("Det gamle passordet var ikke riktig")
            }else if (error.response.status === 400) {
                toast.error("Det nye passordet er ikke gyldig")
            }else {
                toastError(error);
            }
        });
    };

    const signOut = () => {
        axios.post(getBackendURL() + "/api/v1/logout").then(() => {
            console.log("200 response on signout");
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            console.log("Sign out complete");
            window.location.reload();
        });
    };

    const signOutPressed = () => {
        signOut();
    };

    const deleteAccountPressed = () => {
        setShowDeleteInformation(true)
    };

    const createStripePortalLink = () => {
        setCreatingStripePortalLink(true)
        axios.post(getBackendURL() + "/api/v1/user/create-customer-portal-link").then((result) => {
            window.location.href = result.data
        }).catch((error) => {
            setCreatingStripePortalLink(false)
        })
    }

    return (
        <div>
            <Heading2 className={"mb-5"}>Min profil</Heading2>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className={"flex flex-wrap gap-6"}>
                        <InputTextField
                            style={{width: "100%"}}
                            name={"firstName"}
                            type={"text"}
                            label={"Fornavn"}
                            value={currentUser?.firstName}
                            validation={{
                                required: {value: true, message: "First name is required"},
                                minLength: {value: 2, message: "First name is too short"},
                                maxLength: {value: 255, message: "First name is too long"}
                            }}
                        />
                        <InputTextField
                            style={{width: "100%"}}
                            name={"lastName"}
                            type={"text"}
                            label={"Etternavn"}
                            value={currentUser?.lastName}
                            validation={{
                                required: {value: true, message: "Last name is required"},
                                minLength: {value: 2, message: "Last name is too short"},
                                maxLength: {value: 255, message: "Last name is too long"}
                            }}
                        />
                        <InputTextField
                            style={{width: "100%"}}
                            name={"email"}
                            type={"email"}
                            label={"E-post"}
                            value={currentUser?.email}
                            validation={{
                                required: {value: true, message: "Email is required"},
                                minLength: {value: 5, message: "Email is too short"},
                                maxLength: {value: 255, message: "Email is too long"},
                                pattern: {value: /\S+@\S+\.\S+/, message: "Email is invalid"}
                            }}
                        />
                    </div>
                        <Heading2 className={"flex mt-6 !text-lg"} withoutMargin={true}>Passord</Heading2>
                        <PasswordInput
                            style={{width: "100%"}}
                            name={"oldPassword"}
                            label={"Gammelt passord"}
                            validation={{
                                minLength: {value: 8, message: "Old password is too short"},
                                maxLength: {value: 255, message: "Old password is too long"}
                            }}
                        />
                        <PasswordInput
                            style={{width: "100%"}}
                            name={"newPassword"}
                            label={"Nytt password"}
                            validation={{
                                required: {value: methods.getValues("oldPassword") && methods.getValues("oldPassword").length > 1, message: "New password is required"},
                                minLength: {value: 8, message: "Old password is too short"},
                                maxLength: {value: 255, message: "Old password is too long"}
                            }}
                        />
                    <MainButton className={"my-6"}>Oppdater profil</MainButton>

                    <Heading2 className={"mt-14"}>Betaling & abonnement</Heading2>
                    <HeadingDescriptor leftAligned={true} withoutMargin={true}>
                        Administrer ditt abonnement og kortdetaljer i Stripe kundeportalen.
                    </HeadingDescriptor>
                    <MainButton
                        className={"mt-4"}
                        showProcessingAnimation={creatingStripePortalLink}
                        onClickEvent={createStripePortalLink}
                    >
                        Administrer abonnement
                    </MainButton>

                    <Heading2 className={"mt-14"}>Kontakt oss</Heading2>
                    <HeadingDescriptor leftAligned={true} withoutMargin={true}>Har du spørsmål? Send oss en e-post så
                        svarer vi deg så fort vi kan. </HeadingDescriptor>
                    <a
                        href="mailto:kontakt@enthemed.no"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline hover:text-blue-700"
                    >
                        kontakt@enthemed.no
                    </a>

                </form>
            </FormProvider>
            <div className="border-b border-gray-200 pb-10"></div>
            <Heading2 className={"mt-14"}>Logg ut</Heading2>
            <HeadingDescriptor leftAligned={true} withoutMargin={true}>
                Logg ut av Enthemed applikasjonen.
            </HeadingDescriptor>
            <DestructiveButton
                className={"my-4"}
                onClickEvent={signOutPressed}>
                Logg ut
            </DestructiveButton>

            <Heading2 className={"mt-14"}>FARE SONE</Heading2>
            {showDeleteInformation ? (
                <p>
                    For å slette kontoen din og alle dataene vi har lagret
                    om deg, send en henvendelse til&nbsp;
                    <a className={"underline text-blue-700"} href={"mailto:kontakt@enthemed.no"}>
                        kontakt@enthemed.no
                    </a>
                </p>
            ) : (
                <DestructiveButton
                    className={"mt-5"}
                    onClickEvent={deleteAccountPressed}>
                    Slett konto
                </DestructiveButton>
            )}

        </div>
    );
}

export const AccountFragment: SettingsFragment = {
    name: "Account",
    fragmentNode: <AccountFragmentNode/>
}
