import React from "react";
import {PopupComponent} from "./modals/PopupComponent";
import Heading1 from "./text/Heading1";
import HeadingDescriptor from "./text/HeadingDescriptor";

interface SuccessPopupProps {
    handleCancelClick: () => void;
    isVisible: boolean;
}

const SuccessPopup: React.FC<SuccessPopupProps> = ({ handleCancelClick, isVisible }) => {
    return (
        <PopupComponent closePopup={handleCancelClick} isVisible={isVisible}>
            <div className="text-center">
                <Heading1 className="text-2xl font-bold mb-4">Nedlastning fullført!</Heading1>
                <HeadingDescriptor>Gå til Shopify nettbutikken din for å fullføre opplastningen av ditt Enthemed tema</HeadingDescriptor>
                <div className="relative w-full h-0 pb-[56.25%] mb-4">
                    <iframe
                        src="https://fast.wistia.com/embed/medias/tchpiqcpyy"
                        title="Enthemed Video"
                        className="wistia_swatch absolute top-0 left-0 w-full h-full"
                        allowFullScreen
                    ></iframe>
                </div>

            </div>
        </PopupComponent>
    );
};

export default SuccessPopup;
