import React, {useEffect, useState} from 'react';
import Heading2 from "../components/text/Heading2";
import ThemeGridElement from "../components/ThemeGridElement";
import ThemeDetailsPopup from "../components/ThemeDetailsPopup";
import SuccessPopup from "../components/DownloadThemeSucessPopup";
import {getBackendURL} from "../utils/EnvironmentsManager";
import {Theme} from "../types/Theme";
import {useThemes} from "../providers/ThemesProvider";

const ThemePage = () => {
    const {themes} = useThemes()
    const [isDetailsPopupVisible, setIsDetailsPopupVisible] = useState(false);
    const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
    const [selectedTheme, setSelectedTheme] = useState<Theme | null>(null);

    const handleDetailsButtonClick = (theme: Theme) => {
        setSelectedTheme(theme);
        setIsDetailsPopupVisible(true);
    };

    const handleDownloadClick = () => {
        window.open(
            getBackendURL() + "/api/v1/theme/" + selectedTheme?.id + "/download",
            '_blank'
        );
        setIsDetailsPopupVisible(false);
        setIsSuccessPopupVisible(true);
    };

    const handleDetailsPopupClose = () => {
        setIsDetailsPopupVisible(false);
    };

    const handleSuccessPopupClose = () => {
        setIsSuccessPopupVisible(false);
    };

    return (
        <div className="container mx-auto px-10 py-5">
            <Heading2 className="text-left text-4xl mt-3">Nettbutikk temaer</Heading2>
            <p className="text-gray-500 text-left text-md mt-2">
                Designet av eksperter. Bygg og lanser din nettbutikk raskt og effektivt.
            </p>
            <div className="border-b border-gray-200 my-5 mb-10"></div>

            <div className="gap-y-10 grid 2xl:grid-cols-2 2xl:space-x-4">
                {themes && themes.map((theme, index) => (
                    <ThemeGridElement
                        key={index}
                        imageSrc={theme.imageSrc}
                        title={theme.title}
                        description={theme.shortDescription}
                        buttonText="Last ned"
                        onButtonClick={() => handleDetailsButtonClick(theme)}
                        badgeTexts={theme.badges}
                    />
                ))}
            </div>
            <div className="border-b border-gray-200 pb-10"></div>

            {selectedTheme && (
                <ThemeDetailsPopup
                    handleCancelClick={handleDetailsPopupClose}
                    isVisible={isDetailsPopupVisible}
                    handleDownloadClick={handleDownloadClick}
                    imageSrc={selectedTheme.imageSrc}
                    title={selectedTheme.title}
                    previewLink="#"
                />
            )}

            <SuccessPopup
                handleCancelClick={handleSuccessPopupClose}
                isVisible={isSuccessPopupVisible}
            />
        </div>
    )
}

export default ThemePage;
