import Heading1 from "../../components/text/Heading1";
import HeadingDescriptor from "../../components/text/HeadingDescriptor";
import Settings from "./components/Settings";
import {AccountFragment} from "./components/AccountFragment";
import SettingsFragment from "./components/SettingsFragment";

const GeneralSettingsPage = () => {
    const settingsPages:SettingsFragment[] = [AccountFragment]
    return (
        <div className={"container mx-auto w-auto p-10"}>
            <Heading1>Innstillinger</Heading1>
            <HeadingDescriptor withoutMargin={true}>Her kan du se innstillingene dine</HeadingDescriptor>
            <Settings pages={settingsPages}/>
        </div>
    )
}

export default GeneralSettingsPage