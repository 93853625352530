import React, {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import InputTextField from "../../components/input-fields/InputTextField";
import MainButton from "../../components/buttons/MainButton";
import Heading2 from "../../components/text/Heading2";
import PasswordInput from "../../components/input-fields/PasswordInput";
import LinkText from "../../components/input-fields/LinkText";
import DividerWithText from "../../components/authentication/DividerWithText";
import GooglePlaceholderButton from "../../components/buttons/GooglePlaceholderButton";
import FacebookPlaceholderButton from "../../components/buttons/FacebookPlaceholderButton";
import {getBackendURL} from "../../utils/EnvironmentsManager";
import {useSearchParams} from "react-router-dom";
import {useLoginProvider} from "../../providers/LoginProvider";
import {useNavigate} from "react-router";


const SignInPage = () => {
    const methods = useForm()
    const [password, setPassword] = useState('');
    const [queryParameters] = useSearchParams()
    const {authenticated} = useLoginProvider()
    const navigate = useNavigate()

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    useEffect(() => {
        if (authenticated) {
            navigate("/")
        }
    }, [authenticated]);

    return (
        <>
            <FormProvider {...methods} >
                <div className="flex min-h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <img
                            className="mx-auto h-10 w-auto"
                            src="/enthemed-1.png"
                            alt="Enthemed"
                        />
                    </div>

                    <div className="mt-10 sm:max-w-[480px] sm:mx-auto sm:w-full">
                        <div className="bg-white px-6 py-12 shadow-lg sm:rounded-lg sm:px-12">
                            <Heading2 className={"flex justify-center text-4xl mb-8"}>
                                Logg inn
                            </Heading2>

                            {queryParameters.get("error") && <div className={"font-bold text-red-500 text-center"}>{queryParameters.get("error")}</div>}
                            {queryParameters.get("success") && <div className={"font-bold text-green-500 text-center"}>{queryParameters.get("success")}</div>}
                            <form className="space-y-4" action={getBackendURL() + "/api/v1/login"} method={"post"}>
                                <InputTextField
                                    name={"email"}
                                    type={"email"}
                                    label={"E-post"}
                                    style={{width: "100%"}}
                                    validation={{
                                        required: {value: true, message: "Email is required"},
                                        maxLength: {value: 50, message: "The Email is too long"}
                                    }}/>

                                <PasswordInput
                                    name={"password"}
                                    label="Passord"
                                    value={password}
                                    style={{width: "100%"}}
                                    onValueChange={handlePasswordChange}
                                />

                                <div className="flex items-center justify-between">
                                    <LinkText className={"text-sm"} href={"/forgot-password"}>
                                        Glemt passord?
                                    </LinkText>
                                </div>
                                <MainButton className={"w-full"}>Logg inn</MainButton>

                            </form>
                            <div>
                                <div className="relative mt-5">
                                    <DividerWithText>eller fortsett med</DividerWithText>
                                </div>

                                <div className="mt-5 grid grid-cols-1 gap-4"> {/* TODO: ENDRE TIL GRID-COLS-2 NÅR FB LOGIN ER PÅ PLASS */}
                                    <GooglePlaceholderButton/>
                                    {/*<FacebookPlaceholderButton/>*/}
                                </div>
                            </div>
                            <p className={" text-sm text-gray-400 text-center mt-5"}>
                                Har du ikke konto?
                                <LinkText href={"/register"}>
                                    Få en 10 dagers gratis prøveperiode
                                </LinkText>
                            </p>
                        </div>
                    </div>
                </div>
            </FormProvider>
        </>
    )
}
export default SignInPage;