import React, {useEffect, useState} from "react";
import {FieldValues, FormProvider, useForm} from "react-hook-form";
import axios from "axios";
import {getBackendURL} from "../../utils/EnvironmentsManager";
import {MenuOption} from "../../components/input-fields/DropdownMenu";
import Spinner from "../../components/loading/Spinner";
import DestructiveButton from "../../components/buttons/DestructiveButton";
import MainButton from "../../components/buttons/MainButton";
import {toast} from "react-hot-toast";
import {toastError} from "../../utils/ErrorHandlerUtils";
import {ProductSuggestionRule} from "./types/ProductSuggestionRule";
import {Product} from "../../types/Product";
import {createProductSpecifics, extractIdAndVariant, findGraphqlId, findProductHandle} from "./SuggestedProductHelperFunctions";
import {GeneralSuggestion} from "./components/GeneralSuggestion";
import {ProductSuggestionProductSettings} from "./components/ProductSuggestionProductSettings";
import {usePowerUpsProvider} from "../../providers/PowerUpsProvider";
import {ProductConfig} from "../../types/ProductConfig";

export const SuggestedProductsForm = () => {
    const {closeCustomizeMenu, disablePowerUp, setIsSendingData, isSendingData} = usePowerUpsProvider()

    const methods = useForm();
    const [isSaving, setIsSaving] = useState(false);
    const [individualProducts, setIndividualProducts] = useState<ProductConfig[] | undefined>();
    const [productOptions, setProductOptions] = useState<MenuOption[] | undefined>();
    const [productSuggestionRules, setProductSuggestionRules] = useState<ProductSuggestionRule[] | undefined>();

    useEffect(() => {
        getProductSuggestions();
    }, []);

    useEffect(() => {
        getProducts();
    }, [productSuggestionRules]);

    const getProductSuggestions = () => {
        axios.get(getBackendURL() + "/api/v1/power-ups/product-suggestion/list").then(result => {
            setProductSuggestionRules(result.data);
        });
    };

    const getProducts = () => {
        axios.get(getBackendURL() + "/api/v1/product/list").then(result => {
            const products = result.data.map((product: Product) => ({
                checked: productSuggestionRules?.find(rule => rule.sourceProductId === product.id) !== undefined,
                product: product
            }));
            setIndividualProducts(products);

            const menuOptions: MenuOption[] = result.data.flatMap((product: Product) =>
                product.variants.map((variant) => ({
                    id: `${product.id}_${variant.id}`,
                    title: `${product.title}${variant.title !== "Default Title" ? ` (${variant.title})` : ""}`
                }))
            );

            setProductOptions(menuOptions);
        });
    };

    const checkProduct = (individualProduct: ProductConfig) => {
        if (isSendingData) {
            return
        }

        const isChecked = !individualProduct.checked;

        setIndividualProducts(prevProducts =>
            prevProducts!.map(product =>
                product === individualProduct ? {...product, checked: isChecked} : product
            )
        );

        if (!isChecked) {
            // Reset form values for unchecked products
            methods.resetField(individualProduct.product.id + "_suggestion");
            methods.resetField(individualProduct.product.id + "_discount");

            methods.unregister(individualProduct.product.id + "_suggestion")
            methods.unregister(individualProduct.product.id + "_discount")
        }
    };

    const onSubmit = (values: FieldValues) => {
        if (isSendingData) {
            return
        }
        setIsSendingData(true)
        setIsSaving(true)

        const {id: generalSuggestionId, variantId: generalVariantId} = extractIdAndVariant(values.generalSuggestion);
        const generalHandle = findProductHandle(generalSuggestionId, individualProducts || []);
        const graphqlId = findGraphqlId(generalSuggestionId, individualProducts || []);

        const payload = {
            generalId: generalSuggestionId,
            generalGraphqlId: graphqlId,
            generalVariantId: generalVariantId,
            generalDiscount: values.generalDiscount ?? null,
            generalHandle: generalHandle,
            productSpecifics: createProductSpecifics(values, individualProducts || [])
        };

        axios.post(getBackendURL() + "/api/v1/power-ups/product-suggestion/add", payload)
            .then(result => {
                toast.success("Lagret!");
                closeCustomizeMenu();
            })
            .catch(error => {
                toastError(error);
            }).finally(() => {
            setIsSendingData(false)
            setIsSaving(false)
        })
    };

    const disablePowerUpClicked = () => {
        if (isSendingData) {
            return
        }
        setIsSendingData(true)
        disablePowerUp()
    }

    const selectedOption = productSuggestionRules
        ? productOptions?.find(option => {
            const {id: optionId} = extractIdAndVariant(option);
            const generalSuggestion = productSuggestionRules.find(suggestion => suggestion.sourceProductId === null)
            return Number(optionId) === generalSuggestion?.suggestedProductId;
        })
        : undefined;

    const userAttemptsCancel = () => {
        if (isSendingData) {
            return
        }

        closeCustomizeMenu()
    }

    return (
        <>
            {productSuggestionRules && productOptions && individualProducts ? (
                <FormProvider {...methods}>
                    <form className="mt-8" onSubmit={methods.handleSubmit(onSubmit)}>
                        <GeneralSuggestion
                            productOptions={productOptions}
                            selectedOption={selectedOption}
                            generalDiscount={productSuggestionRules.find(suggestion => suggestion.sourceProductId === null)?.discount}
                        />

                        <div className="flex mt-5 justify-center font-bold text-lg">eller</div>
                        <div className="text-xl font-bold">Tilpass hvert produkt</div>

                        <div className={"max-h-96 overflow-y-scroll"}>
                            {individualProducts.map(individualProduct => (
                                <ProductSuggestionProductSettings
                                    key={individualProduct.product.id}
                                    individualProduct={individualProduct}
                                    productOptions={productOptions}
                                    productSuggestionRules={productSuggestionRules}
                                    checkProduct={checkProduct}
                                />
                            ))}
                        </div>
                        <div className="mt-5 flex justify-center gap-5">
                            <DestructiveButton onClickEvent={disablePowerUpClicked}
                                               disabled={isSendingData}>Deaktiver</DestructiveButton>
                            <MainButton showProcessingAnimation={isSaving} disabled={isSendingData}>Lagre</MainButton>
                        </div>
                    </form>
                </FormProvider>
            ) : (
                <Spinner className="flex justify-center"/>
            )}
        </>
    );
};

export default SuggestedProductsForm;