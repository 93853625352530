import ConnectToShopifyStep from "../onboarding/ConnectToShopifyStep";
import IntegrateWithShopifyStep from "../onboarding/IntegrateWithShopifyStep";
import * as React from "react";
import InputStoreNameStep from "../onboarding/InputStoreNameStep";
import ConfigureAppStep from "../onboarding/ConfigureAppStep";
import BuyExtraStoreLicenseStep from "./components/BuyExtraStoreLicenseStep";
import {useEffect} from "react";
import axios from "axios";
import {getBackendURL} from "../../utils/EnvironmentsManager";
import {useStoreProvider} from "../../providers/StoreProvider";
import Spinner from "../../components/loading/Spinner";
import Heading1 from "../../components/text/Heading1";
import WelcomeStep from "../onboarding/WelcomeStep";
import SubscriptionSucceedStep from "../onboarding/SubscriptionSucceedStep";
import SetUpAppStep from "../onboarding/SetUpAppStep";
import FinalStep from "../onboarding/FinalStep";
import {useNavigate} from "react-router";

const ExtraStorePage = () => {
    const {reloadStores} = useStoreProvider()
    const navigate = useNavigate();
    const [step, setStep] = React.useState<number>(-1);

    useEffect(() => {
        setTimeout(() => {
            checkLicenses()
        }, 1500)
    }, []);

    const checkLicenses = () => {
        axios.get(getBackendURL() + "/api/v1/license/available").then(result => {
            const extraLicenseAvailable = result.data
            if (extraLicenseAvailable) {
                setStep(1);
            }else {
                setStep(0);
            }
        }).catch(error => {
            console.log(error);
        })
    };

    const userPressedNext = () => {
        setStep(step + 1);
    }

    const userPressedBack = () => {
        setStep(step - 1);
    }

    const userPressedFinalNext = () => {
        reloadStores && reloadStores()
        navigate("/");
    }

    return (
        <div className={"absolute -z-10 top-0 left-0 flex flex-col w-screen h-screen justify-center items-center px-10 md:pl-28 md:pr-8 lg:px-0"}>
            <div className={"flex flex-col items-center"}>
                {step == -1 && (
                    <>
                        <Heading1>Et øyeblikk</Heading1>
                        <Spinner className={"mt-5"} />
                    </>
                )}
                <BuyExtraStoreLicenseStep onNextPressed={userPressedNext} stepVisible={step == 0}/>
                <InputStoreNameStep onNextPressed={userPressedNext} stepVisible={step == 1}/>
                <IntegrateWithShopifyStep onNextPressed={userPressedNext} stepVisible={step == 2}/>
                <ConfigureAppStep onNextPressed={userPressedNext} onBackPressed={userPressedBack} stepVisible={step === 3}/>
                <SetUpAppStep onNextPressed={userPressedNext} onBackPressed={userPressedBack} stepVisible={step === 4}/>
                <ConnectToShopifyStep onNextPressed={userPressedNext} onBackPressed={userPressedBack} stepVisible={step == 5}/>
                <FinalStep onNextPressed={userPressedFinalNext} stepVisible={step === 6}/>
            </div>
        </div>
    )
}

export default ExtraStorePage;