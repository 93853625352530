import axios from "axios";
import {getBackendURL} from "../../utils/EnvironmentsManager";
import toast from "react-hot-toast";
import React, {useEffect, useState} from "react";
import Heading2 from "../../components/text/Heading2";
import PowerUpGridElement from "../../PowerupGridElement";
import {PowerUpDTO} from "../../types/PowerUpDTO";
import {usePowerUpsProvider} from "../../providers/PowerUpsProvider";
import {powerUpCanBeCustomized} from "./PowerUpMapper";
import {PowerUpButtonState} from "../../enums/PowerUpButtonState";
import Heading3 from "../../components/text/Heading3";
import {Link} from "react-router-dom";
import PowerUpPreview from "./components/PowerUpPreview";
import PowerUpFeedbackPopup from "./PowerUpFeedbackPopup";


const PowerUpsPage = () => {
    const {powerUps, customizePowerUp, disablePowerUp, enablePowerUp} = usePowerUpsProvider()

    const mainButtonPressed = async (powerUpDto: PowerUpDTO) => {
        if (powerUpCanBeCustomized(powerUpDto)) {
            if (!powerUpDto.enabled) {
                await enablePowerUp(powerUpDto)
            }

            customizePowerUp(powerUpDto)
        }else {
            if (powerUpDto.enabled) {
                disablePowerUp(powerUpDto)
            }else {
                enablePowerUp(powerUpDto)
            }
        }
    }

    return (
        <div className="container mx-auto px-10 py-5">
            <Heading2 className="text-left text-4xl mt-3">Powerups</Heading2>
            <p className="text-gray-500 text-left text-md mt-2">
                Gi nettbutikken din superkrefter - med våre "powerups" kan du superenkelt teste konverteringsmetoder.
            </p>
            <div className="border-b border-gray-200 my-5 mb-10"></div>

            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-6">
                {powerUps?.map(powerUp => (
                    <PowerUpGridElement
                        powerUpDTO={powerUp}
                        onButtonClick={() => mainButtonPressed(powerUp)}
                    />
                ))}
            </div>
            <div className={"text-center mt-20"}>
                <Heading3 className={""}>Hvilke powerups vil du se neste?</Heading3>
                <PowerUpFeedbackPopup />
            </div>

        </div>
    );
};

export default PowerUpsPage;
