import {createContext, useContext, useEffect, useState} from "react";
import {User} from "../types/User";
import axios from "axios";
import {getBackendURL} from "../utils/EnvironmentsManager";

export interface UserHolder {
    currentUser?: User;
    refreshUser: () => void;
}

export const UserContext = createContext<UserHolder>({
    refreshUser: () => {}
})

export const useCurrentUser = () => {
    const context = useContext(UserContext);
    return context;
}

export interface UserContextProviderProps {
    children: React.ReactNode
}

export const UserProvider = ({children}: UserContextProviderProps) => {
    const [currentUser, setCurrentUser] = useState<User | undefined>()

    useEffect(() => {
        refreshUser()
    }, []);

    const refreshUser = () => {
        axios.get(getBackendURL() + "/api/v1/user/me").then(results => {
            setCurrentUser(results.data)
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <UserContext.Provider value={{currentUser, refreshUser}}>
            {currentUser && (
                <>
                    {children}
                </>
            )}
        </UserContext.Provider>
    )
}