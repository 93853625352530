import React, { useState } from 'react';
import { PopupComponent } from './components/modals/PopupComponent';
import { InformationCircleIcon } from '@heroicons/react/16/solid'; // Adjust the path as necessary

interface VideoPopupProps {
    videoSrc?: string;
    infoText: string;
}

const VideoToolTip: React.FC<VideoPopupProps> = ({ videoSrc, infoText }) => {
    const [isPopupVisible, setPopupVisible] = useState(false);

    const openPopup = () => {
        setPopupVisible(true);
    };

    const closePopup = () => {
        setPopupVisible(false);
    };

    return (
        <div>
            <div className="rounded-md bg-blue-50 p-5 w-full mt-4">
                <div className="flex justify-center md:justify-start items-center">
                    <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-blue-400 mr-2" />
                    <p className="text-md text-blue-700">
                        {infoText}
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                openPopup();
                            }}
                            className="font-medium text-blue-700 hover:text-blue-600 underline "
                        >
                            {" "}
                            klikk her
                        </a>
                    </p>
                </div>
            </div>

            <PopupComponent closePopup={closePopup} isVisible={isPopupVisible}>
                <div className="relative w-full h-0 pb-[56.25%] mb-4">
                    <iframe
                        src={videoSrc}
                        title="Enthemed Video"
                        className="wistia_swatch absolute top-0 left-0 w-full h-full"
                        allowFullScreen
                    ></iframe>
                </div>
            </PopupComponent>
        </div>
    );
};

export default VideoToolTip;
