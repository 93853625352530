import Heading1 from "../components/text/Heading1";
import { useCurrentUser } from "../providers/UserProvider";
import Heading2 from "../components/text/Heading2";

const HomePage = () => {
    const { currentUser } = useCurrentUser();

    return (
        <div className="flex flex-col items-center min-h-screen justify-center p-4">
            <Heading1>Velkommen, {currentUser && currentUser.firstName}.</Heading1>
            <div className="w-full max-w-4xl mt-6">
                <div className="relative w-full h-0 pb-[56.25%] mb-4">
                    <iframe
                        src="https://fast.wistia.com/embed/medias/6i0e9sup9g"
                        title="Enthemed Video"
                        className="wistia_swatch absolute top-0 left-0 w-full h-full"
                        allowFullScreen
                    ></iframe>
                </div>
                <Heading2 className={"mt-10 italic text-center text-2xl"}>"1% better everyday"</Heading2>
            </div>
        </div>

    );
};

export default HomePage;
