import React from "react";
import {MenuOption} from "../../../components/input-fields/DropdownMenu";
import AutocompleteDropdown from "../../../components/input-fields/AutocompleteDropdown";
import InputPercentField from "../../../components/input-fields/InputPercentField";

interface GeneralSuggestionFieldProps {
    productOptions: MenuOption[];
    selectedOption?: MenuOption;
    generalDiscount?: number;
}

export const GeneralSuggestion = ({productOptions, selectedOption, generalDiscount}: GeneralSuggestionFieldProps) => {
    return (
        <div className={"grid gap-4 sm:grid-cols-2"}>
            <div className="flex flex-row basis-full items-center">
                <span className="w-40">Foreslå alltid</span>
                <AutocompleteDropdown
                    name="generalSuggestion"
                    label="Velg produkt"
                    options={productOptions}
                    style={{width: "100%"}}
                    defaultValue={selectedOption}
                />
                &nbsp;
            </div>

            <div className="flex flex-row basis-full items-center">
                <span className="mr-2">med</span>
                <InputPercentField
                    name="generalDiscount"
                    label=""
                    style={{width: "110px"}}
                    placeholder={"10"}
                    value={generalDiscount}
                    validation={
                        {
                            min: {value: 0, message: "For lavt"},
                            max: {value: 100, message: "For høyt"}
                        }
                    }/>
                <span className="ml-2">rabatt på hvert produkt</span>
            </div>
        </div>

    )
};