import {AnimatePresence, motion} from "framer-motion";
import MainButton from "../../components/buttons/MainButton";
import React, {useState} from "react";
import {Card} from "@mui/material";
import Heading1 from "../../components/text/Heading1";
import InputTextField from "../../components/input-fields/InputTextField";
import {FormProvider, useForm} from "react-hook-form";
import axios from "axios";
import {getBackendURL} from "../../utils/EnvironmentsManager";
import {toastError} from "../../utils/ErrorHandlerUtils";
import toast from "react-hot-toast";
import HeadingDescriptor from "../../components/text/HeadingDescriptor";
import Heading2 from "../../components/text/Heading2";
import {InformationCircleIcon} from "@heroicons/react/16/solid";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import VideoToolTip from "../../VideoToolTip";

interface ConnectToShopifyStepProps {
    onNextPressed: () => void
    onBackPressed: () => void;
    stepVisible: boolean
}

const ConnectToShopifyStep = ({onNextPressed, onBackPressed, stepVisible}:ConnectToShopifyStepProps) => {
    const [loading, setLoading] = useState(false)
    const methods = useForm()

    const validateShopName = () => {
        setLoading(true)

        axios.post(getBackendURL() + "/api/v1/store/connect-to-shopify", {
            "accessToken": methods.getValues("access-token"),
            "apiKey": methods.getValues("api-key"),
            "secretKey": methods.getValues("secret-key")
        }).then(result => {
            onNextPressed()
        }).catch(error => {
            if (error.response.status === 403) {
                toast.error(error.response.data)
                return;
            }
            toastError(error)
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <>
            <AnimatePresence>
                {stepVisible && (
                    <motion.div
                        initial={{ opacity: 0, scaleY: 0 }}
                        animate={stepVisible ? { opacity: 1, scaleY: 1, transition: { delay: 0.6, duration: 0.2 } } : {}}
                        exit={{ opacity: 0, scaleY: 0 }}
                        transition={{ duration: 0.1 }}
                        className={"flex items-center justify-center mt-0"}>

                        <Card className={"flex flex-col items-center md:items-start text-center md:text-left max-h-[650px] w-full max-w-4xl p-6 bg-white shadow-md"}>
                            <div className={"w-full h-[70vh] overflow-y-auto p-10"}>
                                <FormProvider {...methods}>
                                    <Heading1 leftAligned={true}>Koble til Shopify</Heading1>
                                    <HeadingDescriptor withoutMargin={true} leftAligned={true}>
                                        Etter at du har laget Enthemed Custom-appen, må du installere appen og få Admin API-tilgangstoken, API-nøkkelen og API-hemmelighetsnøkkelen
                                    </HeadingDescriptor>
                                    <form className={"flex flex-col gap-y-4"}>
                                        <VideoToolTip
                                            videoSrc={"https://fast.wistia.com/embed/medias/ab7r6gect4"}
                                            infoText="Følg stegene i denne videoen for å fullføre oppsettet,"
                                        />
                                        <div className="border-b border-gray-200 mt-10"></div>

                                        <p className="text-lg text-left mb-4 mt-12">
                                                <Heading2>Steg 10</Heading2>
                                                Trykk på <strong>“install app”</strong>
                                            </p>
                                            <img src={"/enthemed-tutorial/shopifytutorialsteg10.jpg"} alt="Steg 10" />

                                            <p className="text-lg text-left mb-4 mt-12">
                                                <Heading2>Steg 11</Heading2>
                                                Det vil dermed komme en popup, Klikk på <strong>“install”</strong> for å bekrefte
                                            </p>
                                            <img src={"/enthemed-tutorial/shopifytutorialsteg11.jpg"} alt="Steg 11" />

                                            <p className="text-lg text-left mb-4 mt-12">
                                                <Heading2>Steg 12</Heading2>
                                                På <strong>“API credentials”</strong> siden vil du finne din
                                                Admin API nøkkel, trykk på "reveal token once" og kopier innholdet
                                            </p>
                                        <img src={"/enthemed-tutorial/shopifytutorialsteg12.jpg"} alt="Steg 12" />
                                        <img src={"/enthemed-tutorial/shopifytutorialsteg13.jpg"} alt="Steg 13" />


                                        <p className="text-lg text-left mb-4 mt-12">
                                            <Heading2>Steg 13</Heading2>
                                            Lim nøkkelen inn i feltet nedenfor
                                        </p>

                                        <InputTextField
                                            name={"access-token"}
                                            type={"text"}
                                            label={"Admin API Access Token"}
                                            validation={{
                                                required: true,
                                                maxLength: 70,
                                                minLength: 10
                                            }}
                                        />
                                        <p className="text-lg text-left mb-4 mt-12">
                                            <Heading2>Steg 14</Heading2>
                                            Bla litt lenger ned på siden så finner du API nøkkel og API secret key
                                        </p>
                                        <img src={"/enthemed-tutorial/shopifytutorialsteg14.jpg"} alt="Steg 14" />

                                        <p className="text-lg text-left mb-4 mt-12">
                                            <Heading2>Siste steg</Heading2>
                                            Kopier nøkklene inn i feltene under
                                        </p>
                                        <InputTextField
                                            name={"api-key"}
                                            type={"text"}
                                            label={"API key"}
                                            validation={{
                                                required: true,
                                                maxLength: 70,
                                                minLength: 10
                                            }}
                                        />

                                        <InputTextField
                                            name={"secret-key"}
                                            type={"text"}
                                            label={"API secret key"}
                                            validation={{
                                                required: true,
                                                maxLength: 70,
                                                minLength: 10
                                            }}
                                        />

                                        <div className="border-b border-gray-200 mt-5"></div>

                                        <div className={"flex justify-center gap-10 mt-5"}>
                                            <SecondaryButton onClickEvent={onBackPressed}>Gå tilbake</SecondaryButton>
                                            <MainButton onClickEvent={validateShopName} showProcessingAnimation={loading}>Fullfør oppsett</MainButton>
                                        </div>
                                    </form>
                                </FormProvider>
                            </div>
                        </Card>

                    </motion.div>
                )}
            </AnimatePresence>
        </>

    )
}

export default ConnectToShopifyStep