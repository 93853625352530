import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react";
import Sidebar from "./components/navigation/Sidebar";
import { AnimatePresence, motion } from "framer-motion";
import Spinner from "./components/loading/Spinner";
import { StoreProvider } from "./providers/StoreProvider";
import { PowerUpsProvider } from "./providers/PowerUpsProvider";
import { ThemesProvider } from "./providers/ThemesProvider";
import MobileNavBar from "./components/navigation/MobileNavbar";

interface PageWrapperProps {
    sidebarOpen: boolean;
    setSidebarOpen: Dispatch<SetStateAction<boolean>>;
    children: ReactNode;
}

const PageWrapper = ({ sidebarOpen, setSidebarOpen, children }: PageWrapperProps) => {
    const [selectedSidebarItem, setSelectedSidebarItem] = useState("Dashboard");
    const [everythingLoaded, setEverythingLoaded] = useState(false);
    const [storeLoaded, setStoreLoaded] = useState(false);
    const [powerUpsLoaded, setPowerUpsLoaded] = useState(false);

    useEffect(() => {
        if (storeLoaded && powerUpsLoaded) {
            setEverythingLoaded(true);
        }
    }, [storeLoaded, powerUpsLoaded]);

    return (
        <>
            <StoreProvider onStoreChange={() => setStoreLoaded(true)}>
                <PowerUpsProvider onPowerUpsChange={() => setPowerUpsLoaded(true)}>
                    <ThemesProvider>
                        <AnimatePresence>
                            {everythingLoaded ? (
                                <motion.div
                                    key={"test"}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <MobileNavBar
                                        isOpen={sidebarOpen}
                                        selectedSidebarItem={selectedSidebarItem}
                                        setSelectedSidebarItem={setSelectedSidebarItem}
                                    />
                                    {sidebarOpen ? (
                                        <motion.div
                                            initial={{opacity: 0}}
                                            animate={sidebarOpen ? {opacity: "60%"} : {}}
                                            transition={{duration: 0.2}}
                                            className="fixed h-screen top-0 left-0 w-screen bg-black z-40 opacity-25"
                                            onClick={() => setSidebarOpen(false)}
                                        />
                                    ) : null}

                                    <div className="sm:mx-20">{children}</div>

                                    <Sidebar
                                        open={sidebarOpen}
                                        setOpen={setSidebarOpen}
                                        selectedSidebarItem={selectedSidebarItem}
                                        setSelectedSidebarItem={setSelectedSidebarItem}
                                        className="fixed top-0 z-40 lg:block"
                                    />
                                </motion.div>
                            ) : (
                                <motion.div
                                    key={"test2"}
                                    initial={{ opacity: 1 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                    className={"flex w-screen h-screen items-center justify-center"}
                                >
                                    <motion.div
                                        initial={{ opacity: 0, scale: 0.5 }}
                                        animate={{ opacity: 1, scale: 1 }}
                                        exit={{ opacity: 0, scale: 0.5 }}
                                        className="flex flex-col items-center"
                                    >
                                        <img src={"/enthemed-1.png"} alt={"Enthemed"} className={"h-16 mb-6"} />
                                        <Spinner />
                                    </motion.div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </ThemesProvider>
                </PowerUpsProvider>
            </StoreProvider>
        </>
    );
};

export default PageWrapper;
