import {ReactNode, useEffect, useState} from "react";
import ListItem from "@mui/material/ListItem";
import Heading3 from "../../../components/text/Heading3";
import SettingsFragment from "./SettingsFragment";
import List from "@mui/material/List";

interface SettingsProps {
    pages: SettingsFragment[]
}

const Settings = ({pages}: SettingsProps) => {
    const [activeSettingsPage, setActiveSettingsPage] = useState<SettingsFragment | undefined>()

    useEffect(() => {
        setActiveSettingsPage(pages ? pages[0] : undefined)
    }, []);

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center sm:px-6 lg:px-8 mb-8">
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[880px]">
                <div className="bg-white px-6 py-12 shadow-lg sm:rounded-lg sm:px-12">
                    <div className="flex items-center justify-between">
                        <div className="w-full p-5">
                            {activeSettingsPage?.fragmentNode}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Settings;
