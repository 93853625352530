import Heading2 from "../text/Heading2";
import HeadingDescriptor from "../text/HeadingDescriptor";
import {Link} from "react-router-dom";
import React from "react";

interface ForgotPasswordSuccessProps {
    onTryDifferentEmail: () => void;
}

const ForgotPasswordSuccessComponent: React.FC<ForgotPasswordSuccessProps> = ({ onTryDifferentEmail }) => {
    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-10 w-auto"
                        src="/enthemed-1.png"
                        alt="Enthemed"
                    />
                </div>
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <Heading2 className={"flex justify-center text-4xl mb-8"}>
                            Glemt passord?
                        </Heading2>
                        <HeadingDescriptor>
                            Takk! Hvis e-post adressen samsvarer med en e-post vi har registrert,
                            så har vi sendt deg en e-post med videre instruksjoner for å tilbakestille
                            passordet ditt.
                        </HeadingDescriptor>
                        <HeadingDescriptor>
                            Hvis du ikke har mottatt en e-post innen 5 minutter, vennligst sjekk spam-mappen din,{" "}
                            <Link to="https://example.com/terms" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
                                send på nytt
                            </Link>
                            {" "}eller du kan{" "}
                            <button onClick={onTryDifferentEmail} style={{color: 'blue', cursor: 'pointer'}}>
                                prøve en annen e-post
                            </button>
                        </HeadingDescriptor>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ForgotPasswordSuccessComponent;