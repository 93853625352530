import React, {useState} from "react";
import {FieldValues, FormProvider, useForm} from "react-hook-form";
import Heading2 from "../../components/text/Heading2";
import InputTextField from "../../components/input-fields/InputTextField";
import MainButton from "../../components/buttons/MainButton";
import PasswordInput from "../../components/input-fields/PasswordInput";
import CheckBoxComponent from "../../components/checkboxes/CheckBoxComponent";
import GooglePlaceholderButton from "../../components/buttons/GooglePlaceholderButton";
import FacebookPlaceholderButton from "../../components/buttons/FacebookPlaceholderButton";
import DividerWithText from "../../components/authentication/DividerWithText";
import {useNavigate} from "react-router";
import HeadingDescriptorGreyText from "../../components/text/HeadingDescriptorGreyText";
import {Link} from "react-router-dom";
import axios from "axios";
import {getBackendURL} from "../../utils/EnvironmentsManager";
import {useLoginProvider} from "../../providers/LoginProvider";
import LinkText from "../../components/input-fields/LinkText";


const SignUpPage = () => {
    const {checkLogin} = useLoginProvider()
    const methods = useForm()
    const navigate = useNavigate()
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const [password, setPassword] = useState('');

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const onSubmit = (values: FieldValues) => {
        const data = {
            "firstName": values.firstName,
            "lastName": values.lastName,
            "email": values.email,
            "password": password
        }

        axios.post(getBackendURL() + "/api/v1/user/register", data).then((response) => {
            window.location.href = "/"
        }).catch((error) => {
            setErrorMessage(error.response.data.error)
        });
    };

    return (
        <>
            <FormProvider {...methods} >
                <div className="right-0 left-1/2 flex flex-col justify-center sm:px-6 lg:absolute lg:h-screen lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    </div>
                    <div className="mt-10 sm:max-w-[480px] sm:mx-auto sm:w-full">
                        <div className="bg-white px-6 py-12 shadow-lg sm:rounded-lg sm:px-12">
                            <img
                                className="mx-auto h-10 w-auto"
                                src="/Enthemed-icon-1.png"
                                alt="Enthemed"
                            />
                            <Heading2 className={"flex justify-center text-3xl mb-8 mt-5"}>
                                Prøv Enthemed gratis
                            </Heading2>

                            {errorMessage && <div className={"font-bold text-red-500 text-center"}>{errorMessage}</div>}
                            <form className="mt-5 space-y-4" onSubmit={methods.handleSubmit(onSubmit)}>
                                <InputTextField
                                    name={"firstName"}
                                    type={"firstName"}
                                    label={"Fornavn"}
                                    style={{width: "100%"}}
                                    validation={{
                                        required: {value: true, message: 'First name is required'},
                                        maxLength: {value: 50, message: "The Name is too long"},
                                    }}
                                />
                                <InputTextField
                                    name={"lastName"}
                                    type={"lastName"}
                                    label={"Etternavn"}
                                    style={{width: "100%"}}
                                    validation={{
                                        required: {value: true, message: 'the Last name is required'},
                                        maxLength: {value: 50, message: "The Name is too long"},
                                    }}
                                />
                                <InputTextField
                                    name={"email"}
                                    type={"email"}
                                    label={"E-post"}
                                    style={{width: "100%"}}
                                    validation={{
                                        required: {value: true, message: 'Email is required'},
                                        maxLength: {value: 50, message: "The Email is too long"},
                                    }}
                                />
                                <PasswordInput
                                    name={"password"}
                                    label="Passord"
                                    value={password}
                                    style={{width: "100%"}}
                                    onValueChange={handlePasswordChange}
                                />

                                <div className="flex items-center justify-between">
                                    <CheckBoxComponent
                                        name={"terms"}
                                        validation={{required: {value: true, message: 'You must accept the terms and conditions'}}}
                                    >
                                        Jeg har lest og godtatt{" "}
                                        <Link to="/terms-of-service" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
                                            kjøpsvilkårene
                                        </Link>{" "}
                                        og{" "}
                                        <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
                                            personvernerklæringen
                                        </Link>.
                                    </CheckBoxComponent>
                                </div>
                                <MainButton className={"w-full"}>Registrer konto </MainButton>
                            </form>

                            <div>
                                <div className="relative mt-6">
                                    <DividerWithText>eller registrer deg med</DividerWithText>
                                </div>
                                <div className="mt-6 grid grid-cols-1 gap-4"> {/* TODO: ENDRE TIL GRID-COLS-2 NÅR FB LOGIN ER PÅ PLASS */}
                                    <GooglePlaceholderButton/>
                                    {/*<FacebookPlaceholderButton/>*/}
                                </div>
                                <div className={"mt-5 text-center text-sm"}>
                                    <p className={"text-gray-400"}>
                                        Har du allerede en konto?
                                        <LinkText href={"/login"}>
                                            Logg inn
                                        </LinkText>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FormProvider>
            <div className="hidden min-h-screen flex-col lg:flex lg:flex-row">
                <div className="md:w-1/2 md:flex-col lg:flex">
                    <div className="m-auto px-10 space-y-4">
                        <img
                            className="h-auto w-auto"
                            src="/Enthemed-1.svg"
                            alt="Enthemed"
                        />

                    </div>
                </div>
            </div>
        </>
    )
}
export default SignUpPage;